import { Menu, MenuItem } from "@mui/material";
import { CalendarEvent, ics, google, outlook, yahoo } from "calendar-link"
import { useState } from "react";
import { Button } from "components";
import { Service } from "models";
import { FormattedMessage, useIntl } from "services";
import { Temporal } from "@movehq/datetime"

interface Props {
    service: Service;
}

export const AddToCalendar: React.FC<Props> = ({ service }) => {
    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (!service.plannedDatetimeRange || !service.plannedDatetimeRange.begin) {
        return null;
    }
    const plannedDateTime = Temporal.Instant.from(service.plannedDatetimeRange.begin).toString();
    const event: CalendarEvent = {
        title: formatMessage({ id: "surveyPanel.addToCalendar.event.title" }),
        description: `Join a virtual survey with our survey agent to document all the items being moved for your upcoming move. \
        During this session, the agent will guide you through a video call to assess and record the inventory of your household goods. \
        Please <a href=\"${window.location.href}\">click this link</a> to join your Virtual Survey.`,
        start: plannedDateTime,
        duration: [30, 'minutes'],
    };
    const onClickMenuItem = (cb: (calendarEvent: CalendarEvent) => string) => {
        handleCloseMenu();
        const url = cb(event);
        window.open(url, '_blank');
    }

    return (
        <>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
                size="medium"
                variant="secondary"
            >
                <FormattedMessage id="surveyPanel.addToCalendar.button" />
            </Button>
            <Menu anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                open={open}
                onClose={handleCloseMenu}
                hideBackdrop
            >
                <MenuItem onClick={() => { onClickMenuItem(ics) }}>
                    <FormattedMessage id="surveyPanel.addToCalendar.apple" />
                </MenuItem>
                <MenuItem onClick={() => { onClickMenuItem(google) }}><FormattedMessage id="surveyPanel.addToCalendar.google" /></MenuItem>
                <MenuItem onClick={() => { onClickMenuItem(ics) }}><FormattedMessage id="surveyPanel.addToCalendar.ical" /></MenuItem>
                <MenuItem onClick={() => { onClickMenuItem(outlook) }}><FormattedMessage id="surveyPanel.addToCalendar.outlook" /></MenuItem>
                <MenuItem onClick={() => { onClickMenuItem(yahoo) }}><FormattedMessage id="surveyPanel.addToCalendar.yahoo" /></MenuItem>
            </Menu>
        </>
    );
}
