"use client";
import {
  asyncWithLDProvider,
  useFlags as useLdFlags,
} from "launchdarkly-react-client-sdk";
import { debug, getEnv } from "tools";
import { ReactNode, use, useMemo } from "react";

if (getEnv("NEXT_PUBLIC_LD_BASE_URL").includes("localhost")) {
  debug(
    "Using local LD server - please make sure this is running in dev tooling."
  );
}

const ssrFallbackPromise = new Promise<
  ({ children }: { children: ReactNode }) => JSX.Element
>((res) => res(({ children }) => <>{children}</>));

const Provider =
  typeof window !== "undefined"
    ? asyncWithLDProvider({
        clientSideID: getEnv("NEXT_PUBLIC_LD_CLIENT_ID") ?? "",
        options: {
          baseUrl: getEnv("NEXT_PUBLIC_LD_BASE_URL"),
          streamUrl: getEnv("NEXT_PUBLIC_LD_STREAM_URL"),
          eventsUrl: getEnv("NEXT_PUBLIC_LD_EVENTS_URL"),
        },
        timeout: 5,
      })
    : ssrFallbackPromise;

/**
 * LDProvider component that wraps children with LaunchDarkly provider
 *
 * @param children - ReactNode representing the child components
 * @param context - Optional context for the provider
 * @returns JSX.Element - Wrapped children with LaunchDarkly provider
 */
export function LDProvider({ children }: { children: ReactNode }) {
  const LDClientProvider = use(Provider);
  return <LDClientProvider>{children}</LDClientProvider>;
}

/**
 * As we add flags in LD make sure to update this type
 */
export type FlagSet = {
  graphServicesListView: boolean;
  newFieldsOnMilitaryBaseScreen: boolean;
  ppmPortal: boolean;
};

/**
 * Typed useFlags from the launch darkly react SDK
 * @returns FlagSet
 */
export const useFlags = () => useLdFlags<FlagSet>();
